import React, { useState } from 'react';
import RequireAuth from "../auth/RequireAuth";
import {Route, Routes} from "react-router-dom";
import Layout, {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Menu from "./Menu";
import "./app.css";
import Settings from "../settings/settings";
//import Dashboard from "../dashboard";
import Customers from "../customers";
import BookingTable from "../booking/table";
import BookingCalendar from "../booking/calendar";
import {Button, Drawer} from "antd";
import { MenuOutlined } from '@ant-design/icons';
import ShiftPlan from "../shiftplan";
import ShiftPlanCalendar from "../shiftplan/calendar";


const App = () => {

    const SIDEBAR_WIDTH = 250;
    const isMobile = window.innerWidth < 1024;

    const [visible, setVisible] = useState(false);

    return <>
        <RequireAuth>
            <Layout>

                {isMobile ?
                    <>
                        <Drawer
                            visible={visible}
                            onClose={() => setVisible(false)}
                            placement={"left"}
                            width={SIDEBAR_WIDTH+"px"}
                            headerStyle={{background: '#282D37'}}
                            bodyStyle={{margin: 0, padding: 0, background: '#282D37'}}
                        >
                            <Menu onSelect={() => setVisible(false)} />
                        </Drawer>
                    </>
                :
                    <Sider
                        width={SIDEBAR_WIDTH+"px"}
                        style={{
                            overflow: 'auto',
                            height: '100vh',
                            position: 'fixed',
                            left: 0,
                        }}
                    >
                        <Menu />
                    </Sider>
                }







                <Layout className="site-layout" style={{ marginLeft: isMobile ? 0 : SIDEBAR_WIDTH }}>
                    <Content className={"app-main-content"}>
                        {isMobile ? <>
                                <Button
                                    className="menu"
                                    type="primary"
                                    icon={<MenuOutlined />}
                                    onClick={() => setVisible(true)}
                                />
                                <br/><br/>
                            </>
                        : null}
                        <Routes>
                            <Route path="/settings" element={<Settings />}/>
                            {/*<Route path="/dashboard" element={<Dashboard />}/>*/}
                            <Route path="/customers" element={<Customers />}/>
                            <Route path="/bookingtable" element={<BookingTable />}/>
                            <Route path="/bookingcalendar" element={<BookingCalendar />}/>
                            <Route path="/shiftplan" element={<ShiftPlan />}/>
                            <Route path="/shiftplancalendar" element={<ShiftPlanCalendar />}/>
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </RequireAuth>
    </>;
};

export default App;
