import React, {useEffect, useState} from 'react';
import api from "../../../middleware/api";
import {Row, Col, Input, Modal, Spin, Select, Button, Checkbox, DatePicker, Divider, Tabs, TimePicker} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import moment from "moment";
import CurrencyInput from "../../../components/CurrencyInput";
import {useAuth} from "../../auth/AuthProvider";

const {TabPane} = Tabs;


const PersonalDetail = ({id, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [mandators, setMandators] = useState([]);
    const { userInfo } = useAuth();


    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`personal/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    birthdate: response.data.birthdate ? moment(response.data.birthdate) : undefined,
                    entryDate: response.data.entryDate ? moment(response.data.entryDate) : undefined,
                    exitDate: response.data.exitDate ? moment(response.data.exitDate) : undefined,
                    //breaks: response.data.breaks && Array.isArray(response.data.breaks) ? response.data.breaks.map(actBreak => moment(actBreak, 'HH:mm')) : undefined,
                    breaks: response.data.breaks && Array.isArray(response.data.breaks) ? response.data.breaks.map(actWBreak => { return {...actWBreak, time: moment(actWBreak.time, 'HH:mm'), duration: moment(actWBreak.duration, 'HH:mm'), }; }) : undefined,
                    workingDays: response.data.workingDays && Array.isArray(response.data.workingDays) ? response.data.workingDays.map(actWDay => { return {...actWDay, time: moment(actWDay.time, 'HH:mm'), }; }) : undefined,
                    employmentLimit: response.data.employmentLimit ? moment(response.data.employmentLimit) : undefined,
                };
                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`personal/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };

    useEffect(() => {
        loadMandators();
    }, []);

    useEffect(() => {
        setData({});
        if (id && id !== 'new') {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);

    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            values = {
                ...values,
                birthdate: values.birthdate ? values.birthdate.local().format("YYYY-MM-DD") : undefined,
                entryDate: values.entryDate ? values.entryDate.local().format("YYYY-MM-DD") : undefined,
                exitDate: values.exitDate ? values.exitDate.local().format("YYYY-MM-DD") : undefined,
                //breaks: values.breaks && Array.isArray(values.breaks) ? values.breaks.map(actBreak => actBreak.local().format("HH:mm")) : undefined,
                breaks: values.breaks && Array.isArray(values.breaks) ? values.breaks.map(actWBreak => { return {...actWBreak, time: actWBreak.time.local().format("HH:mm"), duration: actWBreak.duration.local().format("HH:mm"), }; }) : undefined,
                workingDays: values.workingDays && Array.isArray(values.workingDays) ? values.workingDays.map(actWDay => { return {...actWDay, time: actWDay.time.local().format("HH:mm"), }; }) : undefined,
                employmentLimit: values.employmentLimit ? values.employmentLimit.local().format("YYYY-MM-DD") : undefined,
            };

            if (id && id !== 'new') {
                await api.patch(`personal/${id}`, values);
            } else {
                await api.put(`personal`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
            console.error(e);
        } finally {
            setSaving(false);
        }
    };

    const loadMandators = async () => {
        const response = await api.get(`mandator`);
        if(response.data) {
            setMandators(response.data);
        }
    };

    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Personal bearbeiten" : "Personal anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"768px"}
            footer={[
                <>
                    {id !== 'new' ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >

                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Mitarbeiterdaten" key="1">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Mitarbeiterdaten</Divider>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Anrede"}
                                        name={"title"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Select>
                                            <Select.Option value={"mr"}>Herr</Select.Option>
                                            <Select.Option value={"mrs"}>Frau</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Vorname"}
                                        name={"firstName"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Nachname"}
                                        name={"lastName"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Straße, Hausnummer"}
                                        name={"address"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"PLZ"}
                                        name={"zipCode"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Ort"}
                                        name={"city"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Geburtsdatum"}
                                        name={"birthdate"}
                                    >
                                        <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Nationalität"}
                                        name={"nationality"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={"Bemerkungen"}
                                        name={"notes"}
                                        required={false}
                                    >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Divider orientation="left">Informationen</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Erstellt"}
                                        name={"createdAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Zuletzt bearbeitet"}
                                        name={"updatedAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Kontaktdaten" key="2">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Kontaktdaten</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail privat"}
                                        name={"mailPrivate"}
                                        rules={[ {type: 'email'}]}
                                    >
                                        <Input type={"email"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail Firma"}
                                        name={"mailBusiness"}
                                        rules={[{type: 'email'}]}
                                    >
                                        <Input type={"email"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Handy"}
                                        name={"phoneMobile"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Festnetz"}
                                        name={"phone"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Fax"}
                                        name={"fax"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>


                        <TabPane tab="Zugangsdaten" key="3">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Zugangsdaten</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Benutzername"}
                                        name={"username"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Aktiv"}
                                        name={"isActive"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Rollen"}
                                        name={"roles"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Select mode="multiple">
                                            { userInfo?.roles?.includes('admin') ? <Select.Option value={"admin"}>Admin</Select.Option> : null}
                                            <Select.Option value={"mandatorAdmin"}>Mandant-Admin</Select.Option>
                                            <Select.Option value={"shiftPlanEditor"}>Schichtplan-Admin</Select.Option>
                                            <Select.Option value={"shiftPlanUser"}>Schichtplan</Select.Option>
                                            <Select.Option value={"user"}>Benutzer</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Passwort ändern"}
                                        name={"password"}
                                    >
                                        <Input type={"password"} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={"Mandanten"}
                                        name={"mandatorIds"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Select mode="multiple">
                                            {mandators.map(mandator => <Select.Option key={mandator.id} value={mandator.id}>{mandator.name}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Beschäftigung" key="4">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Beschäftigung</Divider>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Eintrittsatum"}
                                        name={"entryDate"}
                                    >
                                        <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Austrittsdatum"}
                                        name={"exitDate"}
                                    >
                                        <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.mandatorIds !== curValues.mandatorIds || mandators}>
                                        {() =>
                                            <Form.Item
                                                label={"Betriebsstätte"}
                                                name={'defaultMandatorId'}
                                                required={true}
                                                rules={[{required: true}]}
                                                help={"Es werden nur zugewiesene Mandanten angezeigt."}
                                            >
                                                <Select>
                                                    {
                                                        (form.getFieldValue('mandatorIds') || []).map(mandator => <Select.Option
                                                            key={mandator}
                                                            value={mandator}>{mandators.find(allMandantors => allMandantors.id === mandator).name}</Select.Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        }
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Kostenstelle"}
                                        name={"costCentre"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Ausgeübte Tätigkeit"}
                                        name={"activityPerformed"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Urlaubsanspruch"}
                                        name={"vacationEntitlement"}
                                    >
                                        <Input type={"number"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Arbeitsstunden pro Woche"}
                                        name={"workhoursWeek"}
                                    >
                                        <Input type={"number"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Arbeitsvertrag befristet bis"}
                                        name={"employmentLimit"}
                                    >
                                        <DatePicker style={{width: '100%'}} format={"DD.MM.YYYY"} mode={'date'} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Steuer ID"}
                                        name={"taxNr"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Steuerklasse"}
                                        name={"taxClass"}
                                    >
                                        <Select>
                                            <Select.Option value={"1"}>Klasse 1</Select.Option>
                                            <Select.Option value={"2"}>Klasse 2</Select.Option>
                                            <Select.Option value={"3"}>Klasse 3</Select.Option>
                                            <Select.Option value={"4"}>Klasse 4</Select.Option>
                                            <Select.Option value={"5"}>Klasse 5</Select.Option>
                                            <Select.Option value={"6"}>Klasse 6</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Religion"}
                                        name={"religion"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Kinderfreibetrag"}
                                        name={"childAllowance"}
                                    >
                                        <CurrencyInput />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Krankenversicherung Name"}
                                        name={"healthInsuranceName"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Krankenversicherungsnummer"}
                                        name={"healthInsuranceNr"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Rentenversicherungsnummer"}
                                        name={"pensionInsuranceNr"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Arbeitstage"}
                                        name={"workingDays"}
                                    >
                                        <Select mode={"multiple"}>
                                            <Select.Option value={1}>Montag</Select.Option>
                                            <Select.Option value={2}>Dienstag</Select.Option>
                                            <Select.Option value={3}>Mittwoch</Select.Option>
                                            <Select.Option value={4}>Donnerstag</Select.Option>
                                            <Select.Option value={5}>Freitag</Select.Option>
                                            <Select.Option value={6}>Samstag</Select.Option>
                                            <Select.Option value={7}>Sonntag</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>*/}

                                <Col span={24}>
                                    <Divider orientation="left">Arbeitstage</Divider>
                                </Col>
                                <Col span={24}>
                                    <Form.List
                                        name="workingDays"
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Row gutter={5}>
                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'days']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,

                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Select mode={"multiple"}>
                                                                        <Select.Option value={1}>Montag</Select.Option>
                                                                        <Select.Option value={2}>Dienstag</Select.Option>
                                                                        <Select.Option value={3}>Mittwoch</Select.Option>
                                                                        <Select.Option value={4}>Donnerstag</Select.Option>
                                                                        <Select.Option value={5}>Freitag</Select.Option>
                                                                        <Select.Option value={6}>Samstag</Select.Option>
                                                                        <Select.Option value={7}>Sonntag</Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, 'time']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,

                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <TimePicker
                                                                        style={{ width: '100%' }}
                                                                        format={"HH:mm"}
                                                                        minuteStep={15}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}>
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: '100%' }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Arbeitszeiten hinzufügen
                                                    </Button>
                                                    <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>

                                <Col span={24}>
                                    <Divider orientation="left">Pflichtpausen</Divider>
                                </Col>
                                <Col span={24}>
                                    <Form.List
                                        name="breaks"
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Row gutter={5}>
                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label={"Pause nach"}
                                                                    name={[field.name, 'time']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,

                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TimePicker
                                                                        style={{ width: '100%' }}
                                                                        format={"HH:mm"}
                                                                        minuteStep={15}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label={"Länge der Pause"}
                                                                    name={[field.name, 'duration']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[
                                                                        {
                                                                            required: true,

                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <TimePicker
                                                                        style={{ width: '100%' }}
                                                                        format={"HH:mm"}
                                                                        minuteStep={15}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={4}>
                                                                <Form.Item label={" "}>
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                                    </Form.Item>

                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: '100%' }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Pflichtpause hinzufügen
                                                    </Button>
                                                    <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Vergütung" key="5">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Vergütung</Divider>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Pauschallohn"}
                                        name={"fixedFee"}
                                    >
                                        <CurrencyInput />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Stundenlohn"}
                                        name={"hourlyFee"}
                                    >
                                        <CurrencyInput />
                                    </Form.Item>
                                </Col>


                                {/*<Col span={24}>
                                    <Divider orientation="left">Pflichtpausen</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.List
                                        name="breaks"
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,

                                                                    message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                },
                                                            ]}
                                                            noStyle
                                                        >
                                                            <TimePicker
                                                                style={{ width: '85%' }}
                                                                format={"HH:mm"}
                                                                minuteStep={15}
                                                            />
                                                        </Form.Item>
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    </Form.Item>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: '100%' }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Pause einfügen
                                                    </Button>
                                                    <Form.ErrorList errors={errors} />
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>*/}
                            </Row>
                        </TabPane>
                    </Tabs>

                </Form>
            </Spin>
        </Modal>
    );
}

export default PersonalDetail;
