import React, {useState} from 'react';
import Table from "../../../components/Table";
import moment from "moment";
import PersonalDetail from "./detail";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useAuth} from "../../auth/AuthProvider";

const PersonalTable = () => {

    const [detailId, setDetailId] = useState(null);
    const [toggleReloadState, setToggleReloadState] = useState(null);
    const { userInfo } = useAuth();

    const toggleReload = () => {
        setToggleReloadState(Math.random(5));
    };

    const closeDetail = () => {
        setDetailId(null);
        toggleReload();
    };

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
    };


    const columns = [
        {
            title: 'Anrede',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (data) => Titles?.[data] || ""
        },
        {
            title: 'Vorname',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        },
        {
            title: 'Nachname',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
        },
        {
            title: 'Adresse',
            dataIndex: 'address',
            key: 'address',
            sorter: true,
        },
        {
            title: 'PLZ',
            dataIndex: 'zipCode',
            key: 'zipCode',
            sorter: true,
        },
        {
            title: 'Ort',
            dataIndex: 'city',
            key: 'city',
            sorter: true,
        },
        {
            title: 'Firma',
            dataIndex: 'mandators',
            key: 'mandators',
            render: (data) => ( data.map(m => m.name) || []).join(", "),
        },
        {
            title: 'Rollen',
            dataIndex: 'roles',
            key: 'roles',
            render: (data) => ( data.map(role => role === "user" ? "Benutzer" : role === "admin" ? "Admin" : role === "mandatorAdmin" ? "Mandant-Admin" : role === "shiftPlanEditor" ? "Schichtplan-Admin": role === "shiftPlanUser" ? "Schichtplan" : "N/A") || []).join(", "),
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];

    return (<>
            <PersonalDetail
                id={detailId}
                close={closeDetail}
            />

            <div style={{marginBottom: '15px', textAlign: 'right'}}>
                <Button
                    type={"primary"}
                    icon={<PlusOutlined />}
                    onClick={() => setDetailId("new")}
                >
                    Hinzufügen
                </Button>
            </div>

            <Table
                endpoint={'personal'}
                columns={columns}
                sortField={'createdAt'}
                sortOrder={'descend'}
                onClick={(record) => {
                    if(userInfo?.roles?.includes('admin') || (!userInfo?.roles?.includes('admin') && !record.roles?.includes('admin'))) {
                        setDetailId(record.id)
                    }
                }}
                rowClassName={(record) => {
                    if(userInfo?.roles?.includes('admin') || (!userInfo?.roles?.includes('admin') && !record.roles?.includes('admin'))) {
                        return 'table-clickable';
                    } else {
                        return 'table-row-disabled';
                    }
                }}
                toggleReload={toggleReloadState}
            />
        </>
    );
}

export default PersonalTable;
