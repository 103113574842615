import React from 'react';
import {Menu} from "antd";

import {
    AppstoreOutlined,
    FieldTimeOutlined,
    HomeOutlined,
    UserOutlined,
    BookOutlined,
    TagsOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import {API_URL} from "../../middleware/api";
import {useAuth} from "../auth/AuthProvider";
import MandatorChooser from "./MandatorChooser";
import SidebarUser from "./SidebarUser";
import {useLocation, useNavigate} from "react-router-dom";

const { SubMenu } = Menu;

const SidebarMenu = ({onSelect}) => {

    const {userInfo} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();


    return <>

            <div className="logo">
                <img alt={"logo"} src={`${API_URL}mandator/${userInfo.mandator}/logo.png`} height={"auto"} width={"100%"}/>
            </div>
            <MandatorChooser />
            <Menu
                mode="inline"
                selectedKeys={[location.pathname]}
                defaultOpenKeys={['sub1', 'sub2']}
                onSelect={(route) => { navigate(route.key); onSelect && onSelect(route); }}
            >
                {/*<Menu.Item key="/app/dashboard" icon={<AppstoreOutlined/>}>
                    Übersicht
                </Menu.Item>*/}
                <Menu.Item key="/app/customers" icon={<UserOutlined/>}>
                    Kunden
                </Menu.Item>
                <SubMenu key="sub1" icon={<BookOutlined />} title="Buchungssystem">
                    <Menu.Item key="/app/bookingtable">Alle Reservierungen</Menu.Item>
                    <Menu.Item key="/app/bookingcalendar">Kalender</Menu.Item>
                </SubMenu>
                <Menu.Item disabled key="/app/coupons" icon={<TagsOutlined/>}>
                    Gutscheine
                </Menu.Item>

                <SubMenu key="sub2" icon={<UnorderedListOutlined />} title="Schichtplan">
                    <Menu.Item key="/app/shiftplan">Schichteinteilung</Menu.Item>
                    <Menu.Item key="/app/shiftplancalendar">Auslastung Kalender</Menu.Item>
                </SubMenu>
                <Menu.Item disabled key="/app/workingtime" icon={<FieldTimeOutlined/>}>
                    Zeiterfassung
                </Menu.Item>
                <Menu.Item disabled key="/app/intranet" icon={<HomeOutlined/>}>
                    Intranet
                </Menu.Item>
            </Menu>

            <div className={"sidebar-bottom"}>

                <div className={"sidebar-user"}>
                    <SidebarUser />
                </div>
            </div>
    </>;
};

export default SidebarMenu;
