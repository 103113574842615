import * as moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {Card, Col, DatePicker, message, Row, Select} from "antd";
import Timeline from "react-calendar-timeline";
import TimelineHeaders from "react-calendar-timeline/lib/lib/headers/TimelineHeaders";
import DateHeader from "react-calendar-timeline/lib/lib/headers/DateHeader";
import api from "../../middleware/api";
import {useAuth} from "../auth/AuthProvider";


const getTimeSpan = (date = null) => {

    const actDate = date ? moment(date) : moment();

    /*return {
        start: actDate.startOf("day").valueOf(),
        end: actDate.endOf("day").add(7, 'day').valueOf(),
    };*/

    return {
        start: actDate.startOf("day").add(8, 'hour').valueOf(),
        end: actDate.endOf("day").add(2, 'hour').valueOf(),
    };
};

const itemRenderer = ({ item, itemContext, getItemProps }) => {
    //const backgroundColor = 'var(--global-second-color)';
    let backgroundColor = item.type === "SHIFT" ? '#35dc00' : '#dc8400';
    // if it's a draft, we make it gray
    if(item.draft) {
        backgroundColor = '#d9d9d9';
    }
    return (
        <div
            {...getItemProps({
                style: {
                    backgroundColor: "transparent",
                    padding: "5px 0px",
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    //height: itemContext.dimensions.height,
                },
            })}
        >
            <div style={{backgroundColor, height: "100%", borderRadius: "5px",}}></div>
        </div>
    );
};


const ShiftPlanCalendar = () => {

    const {userInfo} = useAuth();

    const todayTimeSpan = getTimeSpan();

    const [data, setData] = useState({data: [], fromDate: "", untilDate: ""});
    const [groups, setGroups] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [visibleTimeStart, setVisibleTimeStart] = useState(todayTimeSpan.start);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(todayTimeSpan.end);
    const [showDrafts, setShowDrafts] = useState();

    useEffect(() => {
        if(userInfo && userInfo?.roles) {
            const shouldBeAllowed = userInfo?.roles?.includes('admin') || userInfo?.roles?.includes('mandatorAdmin') || userInfo?.roles?.includes('shiftPlanEditor') || false;
            setShowDrafts(shouldBeAllowed);
        }
    }, [userInfo]);

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        if(showDrafts !== undefined) {
            fetchData();
        }
    }, [categoryFilter, showDrafts]);

    const fetchData = async (from = undefined) => {
        try {
            const searchParams = new URLSearchParams({drafts: showDrafts});
            if(from) {
                searchParams.append('from', moment(from).toISOString());
            }
            if(categoryFilter) {
                searchParams.append('category', categoryFilter);
            }

           /* Object.keys(filter).forEach(filterKey => {
                if (filter[filterKey]) {
                    searchParams.append(filterKey, filter[filterKey]);
                }
            });*/


            const response = await api.get(`shift-plan/calendar?${searchParams.toString()}`);

            if(response?.data)
            {
                const shiftData = response.data.shifts.map(shift => {
                    return {
                            start_time: moment(shift.from,"YYYY-MM-DD HH:mm"),
                            end_time: moment(shift.to,"YYYY-MM-DD HH:mm"),
                            title: shift.personal.firstName,
                            id: shift.id,
                            group: shift.personalId,
                            type: "SHIFT",
                            draft: shift.status.startsWith("DRAFT_"),
                            canChangeGroup: false,
                        }
                });

                const groups = response.data.shifts.map(shift => {
                    return {
                        id: shift.personal.id,
                        title: shift.personal.firstName,
                        group: shift.personal.id,
                    };
                });
                // make groups unique by id
                const shiftGroups = groups.filter((group, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === group.id
                    ))
                );

                // bookings

                const bookingsData = response.data.bookings.map(bookingResource => {
                    return bookingResource.bookings.map(bookingSlot => {
                        return {
                            start_time: moment(bookingSlot.startDate, "YYYY-MM-DD HH:mm"),
                            end_time: moment(bookingSlot.endDate,"YYYY-MM-DD HH:mm"),
                            id: bookingSlot.id,
                            group: bookingSlot.booking.resourceId,
                            type: "BOOKING",
                            canChangeGroup: false,
                        }
                    });
                }).flat();

                const bookingGroups = response.data.bookings.map(bookingResource => {
                    return {
                        id: bookingResource.resourceId,
                        title: bookingResource.resourceName,
                        group: bookingResource.resourceId,
                    };
                });

                setGroups([...shiftGroups, ...bookingGroups]);

                setData({...response.data, data: [...shiftData, ...bookingsData]});
            }
        } catch(e) {
            console.error(e);
            message.error("Laden fehlgeschlagen");
        } finally {
        }
    };

    const handleNavigate = (date) => {
        if(!moment(date).isBetween(moment(data.fromDate), moment(data.untilDate))) {
           fetchData(date);
        }
    };


    const onPrevClick = () => {
        const prevDateSpan = getTimeSpan(moment(visibleTimeStart).subtract(1, 'day'));
        setVisibleTimeStart(prevDateSpan.start);
        setVisibleTimeEnd(prevDateSpan.end);

        handleNavigate(prevDateSpan.start);
    };

    const onNextClick = () => {
        const nextDateSpan = getTimeSpan(moment(visibleTimeStart).add(1, 'day'));
        setVisibleTimeStart(nextDateSpan.start);
        setVisibleTimeEnd(nextDateSpan.end);

        handleNavigate(nextDateSpan.start);
    };

    const onTodayClick = () => {
        setVisibleTimeStart(todayTimeSpan.start);
        setVisibleTimeEnd(todayTimeSpan.end);

        handleNavigate(todayTimeSpan.start);
    };

    const jumpToDate = (date) => {
        const nextDateSpan = getTimeSpan(moment(date));
        setVisibleTimeStart(nextDateSpan.start);
        setVisibleTimeEnd(nextDateSpan.end);

        handleNavigate(nextDateSpan.start);
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data);
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Card>
                    <Row>
                        <Col span={20}>
                            <h2>Schichtplan Auslastung</h2>
                        </Col>
                        <Col span={4}>
                            <div style={{textAlign: 'right'}}>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={24}>
                <Card>

                    <div className="rbc-toolbar">
                            <span className="rbc-btn-group">
                                <button type="button" onClick={onTodayClick}>Heute</button>
                                <button type="button" onClick={onPrevClick}>Vorheriger</button>
                                <button type="button" onClick={onNextClick}>Nächster</button>
                            </span>
                        <span className="rbc-toolbar-label"></span>
                        <span className="rbc-btn-group" style={{width: '50%'}}>
                                <Row gutter={15}>
                                    <Col span={12}>
                                        <Select
                                            style={{width: '100%'}}
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={null}
                                            //onChange={(category, val) => (setFilter({category}))}
                                            onChange={(category, val) => (setCategoryFilter(category))}
                                        >
                                            <Select.Option value={null}>--- Alle Kategorien ---</Select.Option>
                                            {categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            style={{width: '100%'}}
                                            onChange={(selectedDate) => jumpToDate(selectedDate)}
                                            placeholder={"Datum"}
                                        />
                                    </Col>
                                </Row>
                            </span>
                    </div>
                    <Timeline
                        selected={[]}
                        groups={groups}
                        //groups={[{id: "test", title: "test", category: "test"}]}
                        //groups={data.data.map(shift => ({id: shift.group, title: shift.title, category: shift.group}))}
                        items={data.data}
                        canMove={false}
                        canResize={false}
                        lineHeight={25}
                        itemHeightRatio={1.0}
                        itemRenderer={itemRenderer}
                        visibleTimeStart={visibleTimeStart}
                        visibleTimeEnd={visibleTimeEnd}
                    >
                        <TimelineHeaders>
                            <DateHeader unit="day" />
                            <DateHeader unit="hour" labelFormat="HH:mm" />
                        </TimelineHeaders>
                    </Timeline>
                </Card>
            </Col>
        </Row>
    );
}

export default ShiftPlanCalendar;