import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Button, Table as AntTable, Space, Input, DatePicker, Typography, message, Checkbox} from "antd";
import moment from "moment";
import ShiftDetail from "./detail";
import {PlusOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import api from "../../middleware/api";
import {useAuth} from "../auth/AuthProvider";

const ShiftType = {
    "WORK": 'Schicht',
    "HOLIDAY": 'Urlaub',
    "ILLNESS": 'Krankheit',
};

const PlusBox = ({onClick, editAllowed}) => {
    if (!editAllowed) return null;
    return (<div onClick={onClick} className="shift-plus-box"><PlusOutlined /></div>);
};

const DraftBanner = ({data, onCommit, onDiscard, onHideDrafts}) => {
    const numberOfDrafts = (data || []).reduce((acc, curr) => acc + curr.shifts.filter(shift => shift.status.startsWith("DRAFT_")).length, 0);
    if (numberOfDrafts === 0) {
        return null;
    }
    return (<div className="shift-draft-banner">
        <Row>
            <Col span={20}>
                <Typography.Title level={4}>Entwurfsansicht</Typography.Title>
                <Typography.Text>Es sind {numberOfDrafts} Änderungen vorhanden.</Typography.Text>
                &nbsp;
                <Typography.Link underline onClick={onHideDrafts}>Veröffentlichten Plan anzeigen</Typography.Link>
            </Col>
            <Col span={4} style={{textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <Space>
                    <Button danger htmlType="button" onClick={onDiscard}>Änderungen verwerfen</Button>
                    <Button type="primary" htmlType="button" onClick={onCommit}>Änderungen veröffentlichen</Button>
                </Space>
            </Col>
        </Row>

    </div>)
};

const SwitchToDraftBanner = ({onShowDrafts}) => {
    return (<div className="shift-draft-banner live">
        <Row>
            <Col span={24}>
                <Typography.Title level={4}>Es wird der veröffentlichte Dienstplan angezeigt</Typography.Title>
                <Typography.Text>Es ist ein noch nicht veröffentlichter Entwurf vorhanden.</Typography.Text>
                &nbsp;
                <Typography.Link underline onClick={onShowDrafts}>Entwurf anzeigen</Typography.Link>
            </Col>
        </Row>

    </div>)
};

const ShiftPlan = () => {

    const {userInfo} = useAuth();

    //const editAllowed = userInfo?.roles?.includes('admin') || userInfo?.roles?.includes('mandatorAdmin') || userInfo?.roles?.includes('shiftPlanEditor') || false;

    const [shiftPlanData, setShiftPlanData] = useState([]);
    const [shiftPlanLoading ,setShiftPlanLoading] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [columns, setColumns] = useState([]);
    const [monday, setMonday] = useState();
    const [showDrafts, setShowDrafts] = useState();
    const [editAllowed, setEditAllowed] = useState();
    const [showDeleted, setShowDeleted] = useState(false);


    useEffect(() => {
        if(userInfo && userInfo?.roles) {
            const shouldBeAllowed = userInfo?.roles?.includes('admin') || userInfo?.roles?.includes('mandatorAdmin') || userInfo?.roles?.includes('shiftPlanEditor') || false;
            setEditAllowed(shouldBeAllowed);
            setShowDrafts(shouldBeAllowed);
        }
    }, [userInfo]);

    const closeDetail = () => {
        setDetailId(null);
        toggleReload();
    };

    const ShiftBox = ({shift, editAllowed}) => {
        const isDraft = shift.status.startsWith("DRAFT_");
        const isDraftDeleted = shift.status === "DRAFT_DELETED";
        const isDeleted = shift.status === "DELETED";

        const fromTime = moment.utc(shift.from);
        const toTime = moment.utc(shift.to);

        const fullDay = fromTime && toTime && fromTime.isSame(toTime, "day") && fromTime.isSame(moment(fromTime).startOf('day'), "minute") && toTime.isSame(moment(toTime).endOf('day'), "minute");


        return (<div onClick={() => editAllowed && setDetailId({ id: shift.id })} className={`shift-box ${editAllowed ? "clickable" : ""} ${shift.type} ${isDraft ? "draft" : ""} ${isDraftDeleted ? "draft-deleted" : ""} ${isDeleted ? "deleted" : ""}`}>
            {ShiftType[shift.type]}
            <br/>
            <b>{fullDay
                ?
                    "Ganzer Tag"
                :
                    `${fromTime.format("HH:mm")} - ${toTime.format("HH:mm")}`
            }</b>
        </div>);
    };


    const selectWeek = (date) => {
        // create columns for Monday to Sunday of current week
        // 1. get current date
        const dateToUse = new Date(date);
        // 2. get monday of given date
        let newMonday = new Date(dateToUse.setDate(dateToUse.getDate() - dateToUse.getDay() + (dateToUse.getDay() === 0 ? -6 : 1)));
        newMonday = moment(newMonday).format('YYYY-MM-DD');
        // 3. create columns from monday to sunday
        const weekColumns = [{
            title: <b>Person</b>,
            dataIndex: "personName",
            key: "personName",
        }];
        for (let i = 0; i < 7; i++) {
            const day = new Date(newMonday);
            day.setDate(day.getDate() + i);
            weekColumns.push({
                title: <div style={{textAlign: "center"}}>
                    <b>{moment(day).format('dddd')}</b><br/>{moment(day).format('DD MMM')}</div>,
                dataIndex: `day${i}`,
                key: `day${i}`,
                //render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
                render: (_, record) =>
                    <Space>{(record?.shifts || []).filter(shift => moment.utc(shift.from).isSame(day, "day")).map(shift =>
                        <ShiftBox editAllowed={editAllowed} shift={shift}/>)}<PlusBox editAllowed={editAllowed}
                        onClick={() => setDetailId({id: "new", day: moment(day).format("YYYY-MM-DD"), personalId: record.personalId })}/></Space>
            });
        }
        setColumns(weekColumns);
        setMonday(newMonday);
    }

    const commitDrafts = async () => {
        try {
            await api.post(`shift-plan/${monday}/draft`);
            await fetchShiftPlan(monday);
        } catch (e) {
            console.error(e);
            message.error("Speichern fehlgeschlagen");
        }
    };

    const discardDrafts = async () => {
        try {
            await api.delete(`shift-plan/${monday}/draft`);
            await fetchShiftPlan(monday);
        } catch (e) {
            console.error(e);
            message.error("Speichern fehlgeschlagen");
        }
    };

    const fetchShiftPlan = async (weekStart) => {
        setShiftPlanLoading(true);
        const response = await api.get(`shift-plan/${weekStart}${showDrafts ? "/draft" : ""}`);
        setShiftPlanLoading(false);
        if(response?.data && Array.isArray(response.data)) {
            let newData = response.data;

            if(!showDeleted) {
                newData = newData.map(person => {
                    person.shifts = person.shifts.filter(shift => shift.status !== "DELETED");
                    return person;
                });
            }
            setShiftPlanData(newData);
        }
    };

    // rerender if editAllowed is changed! is important to avoid edit is not working
    // also select default current week on first render
    useEffect(() => {
        if(editAllowed !== undefined) {
            selectWeek(new Date());
        }
    }, [editAllowed]);

    useEffect(() => {
        if(monday && showDrafts !== undefined) {
            fetchShiftPlan(monday);
        }
    }, [monday, showDrafts, showDeleted]);

    const toggleReload = () => {
        fetchShiftPlan(monday);
    };

    return (<>

        <ShiftDetail
            id={detailId?.id}
            close={closeDetail}
            day={detailId?.day}
            personalId={detailId?.personalId}
            monday={monday}
        />

        <Row>
                <Col span={24}>
                    <Card>
                        <Row gutter={[10, 10]}>
                            <Col span={8}>
                                <h2>Dienstplan</h2>
                            </Col>
                            <Col span={5}>
                                <div style={{textAlign: 'right'}}>
                                    <Space>
                                        <Button type="default" onClick={() => selectWeek(moment(monday).subtract(1, "week").toDate())}><LeftOutlined /></Button>
                                    <DatePicker
                                        value={moment(monday)}
                                        onChange={(date) => selectWeek(date)}
                                        picker="week"
                                        format={(val) => `KW ${moment(val).week()}  ${moment(val).format('YYYY')}`}
                                    />
                                        <Button type="default" onClick={() => selectWeek(moment(monday).add(1, "week").toDate())}><RightOutlined /></Button>
                                    </Space>
                                </div>
                            </Col>
                            <Col span={11}>
                                <div style={{textAlign: 'right'}}>
                                    {editAllowed ? <Checkbox checked={showDeleted}
                                                             onChange={(e) => setShowDeleted(e.target.checked)}>Gelöschte
                                        anzeigen</Checkbox> : null}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
               <Col span={24}>
                   <Card>
                       <DraftBanner data={shiftPlanData} onCommit={commitDrafts} onDiscard={discardDrafts} onHideDrafts={() => setShowDrafts(false)} />
                       {editAllowed && !showDrafts ? (
                           <SwitchToDraftBanner onShowDrafts={() => setShowDrafts(true)} />
                       ) : null}
                       <br/>
                       <AntTable
                           columns={columns}
                           dataSource={shiftPlanData}
                           loading={shiftPlanLoading}
                           pagination={false}
                           scroll={{x: '100%'}}
                           className={"shift-plan-table"}
                           rowClassName={() => "shift-row"}
                       />
                   </Card>
               </Col>
            </Row>
        </>
    );
}

export default ShiftPlan;
