import React, {useEffect, useState} from 'react';
import api from "../../middleware/api";
import {Row, Col, Input, Modal, Spin, Select, Button, DatePicker, Divider, TimePicker, Checkbox} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import moment from "moment";


const ShiftDetail = ({id, monday, day, personalId, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [personal, setPersonal] = useState([]);

    const loadPersonal = async () => {
        const response = await api.get(`shift-plan/personal`);
        if(response.data) {
            setPersonal(response.data);
        }
    };

    useEffect(() => {
        if(id) {
            loadPersonal();
        }
    }, [id]);

    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`shift-plan/shift/${id}`);
            if(response?.data)
            {

                const fromTime = response.data.from ? moment(response.data.from, 'YYYY-MM-DD HH:mm:ss') : undefined;
                const toTime = response.data.to ? moment(response.data.to, 'YYYY-MM-DD HH:mm:ss') : undefined;

                const fullDay = fromTime && toTime && fromTime.isSame(toTime, "day") && fromTime.isSame(moment(fromTime).startOf('day'), "minute") && toTime.isSame(moment(toTime).endOf('day'), "minute");

                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    fullDay,
                    weekday: response.data.from ? moment(response.data.from, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD") : undefined,
                    fromTime,
                    toTime,
                };
                setData(data);

                const isEditDisabled = data?.status === "DRAFT_DELETED" || data?.status === "DELETED";
                setEditDisabled(isEditDisabled);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`shift-plan/shift/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };


    useEffect(() => {
        setEditDisabled(false);
        if(id && id === "new" && day) {
            setData({ weekday: day, personalId, type: "WORK" });
        } else {
            setData({type: "WORK"});
        }
        if (id && id !== 'new') {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);


    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            let resultData = {};
            setSaving(true);

            if(values.fullDay) {
                values.fromTime = moment(values.weekday).startOf('day');
                values.toTime = moment(values.weekday).endOf('day');
            }

           const from = `${values.weekday} ${values.fromTime.format("HH:mm")}`;

           // add 1 day if fromTime is greater than toTime
           const toDate = values.fromTime > values.toTime ? moment(values.weekday).add(1, 'day').format("YYYY-MM-DD") : values.weekday;
           const to = `${toDate} ${values.toTime.format("HH:mm")}`;


            values = {
                ...values,
                from,
                to,
            };

            if (id && id !== 'new') {
                const res = await api.patch(`shift-plan/shift/${id}`, values);
                resultData = res.data;
            } else {
                const res = await api.put(`shift-plan`, values);
                resultData = res.data;
            }

            message.success("Gespeichert");
            close(resultData);
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    const weekdays = [
        {date: moment(monday).format("YYYY-MM-DD"), text: `Montag (${moment(monday).format("DD.MMM")})`},
        {date: moment(monday).add(1, 'day').format("YYYY-MM-DD"), text: `Dienstag (${moment(monday).add(1, 'day').format("DD.MMM")})`},
        {date: moment(monday).add(2, 'day').format("YYYY-MM-DD"), text: `Mittwoch (${moment(monday).add(2, 'day').format("DD.MMM")})`},
        {date: moment(monday).add(3, 'day').format("YYYY-MM-DD"), text: `Donnerstag (${moment(monday).add(3, 'day').format("DD.MMM")})`},
        {date: moment(monday).add(4, 'day').format("YYYY-MM-DD"), text: `Freitag (${moment(monday).add(4, 'day').format("DD.MMM")})`},
        {date: moment(monday).add(5, 'day').format("YYYY-MM-DD"), text: `Samstag (${moment(monday).add(5, 'day').format("DD.MMM")})`},
        {date: moment(monday).add(6, 'day').format("YYYY-MM-DD"), text: `Sonntag (${moment(monday).add(6, 'day').format("DD.MMM")})`},
    ];


    return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={!!id}
                title={id !== 'new' ? "Schicht bearbeiten" : "Schicht anlegen"}
                onCancel={close}
                onOk={handleOk}
                width={"980px"}
                footer={[
                    <>
                        {id !== 'new' && data?.status !== "DELETED" ?
                            <Button key="delete" type="danger" onClick={deleteData}>
                                Löschen
                            </Button>
                        : null}
                    </>,
                    <Button key="cancel" type="default" onClick={close}>
                        Abbrechen
                    </Button>,
                    <>
                        {editDisabled ? null :
                            <Button key="ok" type="primary" onClick={handleOk}>
                                Speichern
                            </Button>
                        }
                    </>,

                ]}
            >
                <Spin spinning={dataLoading || saving}>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={data}
                        layout={"vertical"}
                    >

                                <Row
                                    gutter={16}
                                >
                                    <Col span={24}>
                                        <Divider orientation="left">Schichtinformationen</Divider>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Personal"}
                                            name={"personalId"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Select disabled={editDisabled}>
                                                {personal.map((item) => (
                                                    <Select.Option key={item.personalId} value={item.personalId}>{item.personName}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={"Art"}
                                            name={"type"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Select disabled={editDisabled}>
                                                <Select.Option value={"WORK"}>Schicht</Select.Option>
                                                <Select.Option value={"HOLIDAY"}>Urlaub</Select.Option>
                                                <Select.Option value={"ILLNESS"}>Krankheit</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                    <Col span={12}>
                                        <Form.Item
                                            label={"Wochentag"}
                                            name={"weekday"}
                                            required={true}
                                            rules={[{required: true}]}
                                        >
                                            <Select disabled={id !== "new" || editDisabled}>
                                                {weekdays.map((item) => (
                                                    <Select.Option key={item.date} value={item.date}>{item.text}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.fullDay !== currentValues.fullDay}>
                                            {() => {
                                                const isFullDay = form.getFieldValue('fullDay');
                                                return (
                                                    <Form.Item
                                                        label="Von"
                                                        name="fromTime"
                                                        rules={[
                                                            {
                                                                required: !isFullDay,
                                                            }
                                                        ]}
                                                    >
                                                        <TimePicker
                                                            style={{ width: '100%' }}
                                                            format="HH:mm"
                                                            minuteStep={15}
                                                            disabled={isFullDay || editDisabled}
                                                        />
                                                    </Form.Item>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.fullDay !== currentValues.fullDay}>
                                            {() => {
                                                const isFullDay = form.getFieldValue('fullDay');
                                                return (
                                                    <Form.Item
                                                        label="Bis"
                                                        name="toTime"
                                                        rules={[
                                                            {
                                                                required: !isFullDay,
                                                            }
                                                        ]}
                                                    >
                                                        <TimePicker
                                                            style={{ width: '100%' }}
                                                            format="HH:mm"
                                                            minuteStep={15}
                                                            disabled={isFullDay || editDisabled}
                                                        />
                                                    </Form.Item>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>&nbsp;
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"fullDay"}
                                            valuePropName={"checked"}
                                        >
                                            <Checkbox disabled={editDisabled}>Gesamter Tag</Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Divider orientation="left">Informationen</Divider>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Erstellt"}
                                            name={"createdAt"}
                                        >
                                            <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Zuletzt bearbeitet"}
                                            name={"updatedAt"}
                                        >
                                            <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>&nbsp;</Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={"Zuletzt bearbeitet"}
                                            name={"editorName"}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>

                    </Form>
                </Spin>
            </Modal>
    );
}

export default ShiftDetail;
